.report-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  /* .report-form .form-group {
    margin-bottom: 1.5rem;
  } */
  
  .top-bar__logo {
    max-width: 300px; /* Adjust as needed */
    max-height: 100px; /* Adjust as needed */
  }
  
  .report-title {
    font-family: 'Sans-serif';
    margin: 0;
  }
  
  .report-address {
    font-family: 'Sans-serif';
    margin: 0;
  }
  
  /* .loading {
    margin-top: 1rem;
  } */
  /* Add this to your CSS file */
/* .container {
  max-width: 1200px;
  margin: 0 auto;  
  padding: 20px;    
} */

/* .card-body {
  padding: 20px; 
} */

/* .loading {
  text-align: center;
  padding: 20px;
} */

/* .table {
  margin-top: 20px;
} */


/* Add this to your CSS file */
.loadin-report-page {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height */
  margin: 0;
  padding: 0;
}

/* .centered-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  justify-content: center; 
  width: 100%;
  max-width: 800px; 
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-right: 20px; 
} */

.containers {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center child elements horizontally */
}

.cards {
  margin: 0 auto; /* Center card in its parent */
  width: 100%; /* Full width */
  max-width: 800px; /* Set max width for the card */
}
