.footer {
    color: black;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white; 
  }
  
  .text-black {
    color: black;
  }
  
  .fw-500 {
    font-weight: 500;
  }
  
  .company-name div {
    margin-right: 30px;
    margin-top: 0.5rem;
  }
  