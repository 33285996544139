.responsive-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 90%;
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .responsive-card {
      flex-direction: row;
      padding: 30px;
      max-width: 80%;
    }
  }
  
  .responsive-card img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .responsive-card .content {
    flex: 1;
    margin-left: 20px;
  }
  
  .responsive-card .content h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .responsive-card .content p {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  @media (max-width: 768px) {
    .responsive-card .content {
      margin-left: 0;
      text-align: center;
    }
  }
  