/* ViewQuotation.css */

@media print {
    /* Set A4 size and margins */
    @page {
        size: A4; /* Set to A4 size */
        margin: 10mm; /* Set margin around the content */
    }

    /* Hide navigation and other unnecessary elements */
    .navbar, .footer, .print-button {
        display: none; /* Hide these elements during printing */
    }

    /* Set print layout styles */
    body, .container {
        margin: 0; /* Remove default margin */
        padding: 0; /* Remove default padding */
        width: 100%; /* Full width for printing */
        font-size: 12px; /* Base font size for print */
        color: #000; /* Ensure text is black for better contrast */
    }

    /* Flex container for header elements (logo, No#, total) */
    .header {
        display: flex; /* Use flexbox for alignment */
        justify-content: space-between; /* Space between elements */
        align-items: center; /* Center items vertically */
        margin-bottom: 20px; /* Space below header */
    }

    .logo {
        flex: 0 0 auto; /* Prevent logo from shrinking */
        max-width: 150px; /* Adjust logo size as needed */
    }

    .quotation-details {
        text-align: right; /* Right-align text for No# and total */
        flex: 1; /* Allow this section to take available space */
    }

    /* Customize table styles */
    .table {
        width: 100%; /* Full width for tables */
        font-size: 10px; /* Smaller font size for tables */
        border-collapse: collapse; /* Collapse borders */
    }

    .table th, .table td {
        padding: 4px; /* Add padding for table cells */
        border: 1px solid #000; /* Black borders for table cells */
    }

    /* Ensure the A4 page contains all content without breaking unnecessarily */
    .page-break {
        page-break-before: always; /* Force page break before this element */
    }
}
