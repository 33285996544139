/* Container for the toggle switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 30px; /* Reduced width */
  height: 15px; /* Reduced height */
}

/* Hide the default checkbox input */
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider styling */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Default color when toggle is off */
  transition: .4s;
  border-radius: 15px; /* Rounded corners */
}

/* Container for labels and toggle switch */
.toggle-container {
  display: flex;
  align-items: center;
}

/* Styling for the labels */
.label-no, .label-yes {
  font-size: 10px; /* Reduced font size */
  color: #000; /* Text color for the labels */
  margin: 0 4px; /* Reduced spacing between label and toggle switch */
}

/* Styling for the card */
.card-move-right {
  left: 290px; /* Adjust as needed */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: hidden; /* Hide vertical scrollbar */
  position: absolute; /* Ensure it’s positioned relative to its container */
}

/* Column for quantity */
.quantity-col {
  width: 70px; /* Adjust width as needed */
  text-align: center; /* Center align the content */
}

/* Input field in the quantity column */
.quantity-col input {
  width: 100%; /* Make input fit column width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Slider before pseudo-element (the circle) */
.slider:before {
  position: absolute;
  content: "";
  height: 12px; /* Reduced height */
  width: 12px; /* Reduced width */
  border-radius: 50%; /* Round shape */
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

/* Color when toggle is on */
input:checked + .slider {
  background-color: #003366; /* Blue color */
}

/* Position of the circle when toggle is on */
input:checked + .slider:before {
  transform: translateX(15px); /* Adjust to match slider width */
}

/* Styling for the slider labels */
.slider .labels {
  position: absolute;
  font-size: 8px; /* Further reduced font size */
  color: white;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Position of 'yes' label */
.slider .labels.yes {
  right: 4px; /* Adjusted position */
}

/* Position of 'no' label */
.slider .labels.no {
  left: 2px; /* Adjusted position */
}

/* Input field styling */
.input-field {
  width: 90%; /* Adjust width to fit container */
  height: 18px; /* Reduced height */
  padding: 2px 4px; /* Reduced padding */
  font-size: 10px; /* Smaller font size */
  border: 1px solid #ccc; /* Border style */
  border-radius: 4px; /* Rounded corners */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Input field focus styling */
.input-field:focus {
  border-color: #2196F3; /* Color when focused */
  outline: none; /* Remove default focus outline */
}


/* Placeholder styling */
.input-field::placeholder {
  font-size: 8px; /* Adjust size as needed */
  color: #aaa; /* Adjust color as needed */
  opacity: 1; /* Ensure placeholder is visible */
}
