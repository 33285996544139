.table-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    border: 1px solid #ddd; /* Add border around the container */
    margin: 0; /* Adjust margin for better alignment */
    padding: 0; /* Remove unwanted padding */
  }
  
  .table {
    border-collapse: collapse; /* Merge table borders */
    width: 100%; /* Full width to fit within container */
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd; /* Add lines between rows and columns */
    text-align: center; /* Center-align text */
    padding: 8px; /* Add spacing inside cells */
  }
  
 
  

  

  