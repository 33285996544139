@media print {
    body {
        margin: 0;
        padding: 10px;
        font-family: Arial, sans-serif;
    }
   
    /* Hide the print button in print view */
    .no-print {
        display: none !important;
    }
   
    .print-container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        page-break-inside: avoid; /* Prevents page breaks inside this element */
    }
   
    .print-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #000;
        margin-bottom: 10px;
    }
 
    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }
   
    table, th, td {
        border: 1px solid #000;
        background-color: cadetblue;
        margin-top: 5px;
    }
   
    th, td {
        padding: 10px;
        text-align: left;
    }
   
    .tariff {
        margin-top: 20px;
        font-size: 12px;
    }
 
    .taxbreakdown p {
        font-size: 14px;
    }
 
    .tariffAmount p {
        font-size: 14px;
    }
 
    .note {
        font-style: italic;
        text-align: center;
        margin-top: 30px;
        font-size: 16px;
    }
   
    .signature {
        text-align: center;
        margin-top: 60px;
        font-size: 20px;
    }
 
    /* Printing for Amount in words */
    p.amount-in-words {
        font-size: 14px;
        font-weight: normal;
    }
 
    @page {
        size: auto; 
        margin: 20mm; 
    }
}
 
.print-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 20px;
}
 
.print-button .MuiButton-root {
    background-color: #000080; /* Dark blue */
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}
 
.print-button .MuiButton-root:hover {
    background-color: #003366;
}
.taxbreakdown,
.tariffAmount {
    text-align: right; /* Align all text to the right */
    width: 100%; /* Ensure these containers take up full width */
}

.taxbreakdown p,
.tariffAmount p {
    margin: 5px 0; /* Adjust margin for spacing between paragraphs */
}

.tariffAmount {
    margin-top: 10px; /* Optional: Add some space above "Total Tariff" */
}

.tariffAmount p {
    margin-bottom: 0; /* Ensure no margin below "Total Amount" */
}
.underline {
    width: 100%;
    border-collapse: collapse; /* Ensures no space between cells */
    border: 1px solid #000; /* Adds a border around the table */
}

.underline th, .underline td {
    padding: 8px; /* Adds padding inside cells */
    border: 1px solid #000; /* Adds a border around each cell */
    text-align: left; /* Aligns text to the left */
}

.underline th {
    background-color: #f2f2f2; /* Adds a light gray background to the header */
}

.underline td {
    background-color: #fff; /* Ensures the table rows have a white background */
}