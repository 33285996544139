.tb {
    display: flex;
    flex-direction: column;
    margin-right: 10px; /* Reduce spacing as needed */
  }
  
.nn {
    margin-bottom: 5px; /* Keep this spacing for clarity */
  }
  
  .date-truck-container {
    display: flex;
    align-items: center; /* Align items vertically in the center */
  }
  
  .date-truck-container > div {
    margin-right: 10px; /* Adjust the space between the two dropdowns */
  }
  
  .date-truck-container > div:last-child {
    margin-right: 0; /* Remove margin from the last child to avoid extra space */
  }
  