.toggle-switch {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the toggle switch horizontally */
    gap: 10px; /* Space between the switch and the labels */
    width: 100%; /* Ensure the parent takes full width */
    position: relative;
  }
  
  .toggle-switch label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }
  
  .toggle-switch .slider {
    position: relative;
    width: 40px; /* Reduced width */
    height: 20px; /* Reduced height */
    background: #ccc; /* Off state color */
    border-radius: 10px; /* Half of height for rounded edges */
    transition: background 0.3s;
  }
  
  .toggle-switch input:checked + .slider {
    background:#6e6ef3; /* Dark blue color for on state */
  }
  
  .toggle-switch input {
    display: none; /* Hide the checkbox */
  }
  
  .toggle-switch .slider::before {
    content: "";
    position: absolute;
    width: 16px; /* Adjusted size */
    height: 16px; /* Adjusted size */
    background: white;
    border-radius: 50%;
    top: 2px; /* Center vertically */
    left: 2px; /* Start on the left */
    transition: transform 0.3s;
  }
  
  .toggle-switch input:checked + .slider::before {
    transform: translateX(20px); /* Move based on reduced width */
  }
  
  .toggle-switch .label-left,
  .toggle-switch .label-right {
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }
  