.total-erv-card {
    /* Add some padding and margin for card */
    padding: 20px;
    margin: 10px;
  }
  
  .table-container {
    /* Make table scrollable if it overflows */
    max-height: 400px; /* Adjust as needed */
    overflow-y: auto;
  }
  