/* Ensure the parent container has a relative position */
.form-group {
    position: relative;
  }
  
  /* Style for the dropdown list container */
  .dropdown-list {
    position: absolute;
    background-color: white;
    width: 400px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: 5px;
    padding: 5px 0;
    display: block; /* Ensure the dropdown is visible when opened */
    display: flex;
    flex-direction: column;
  }
  
  /* Ensure proper alignment of the dropdown */
  .input-group {
    position: relative; /* Make sure input container is positioned correctly */
  }
  
  /* Style for each dropdown item */
  .dropdown-item {
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px; /* Adjust font size to improve readability */
  }
  
  /* Highlight dropdown items on hover */
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  
  /* Style for the Cancel button - making it sticky at the bottom */
  .cancel-btn {
    color: blue;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    border-top: 1px solid #ccc;
    background-color: white;
    width: 100%;
    position: sticky;
    bottom: 0; /* Stick it to the bottom of the dropdown */
    z-index: 5; /* Ensure it's above the other items */
  }
  
  /* Change background color on hover for the Cancel button */
  .cancel-btn:hover {
    background-color: #f0f0f0;
  }
  